import {
  Heading,
  HydrateWhenInViewport,
  Illustrations,
  Link,
  Paragraph,
} from '@loveholidays/design-system';
import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { Usp as UspType } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { WithClickTracking } from '@Core/tracking/WithClickTracking';
import { Pictogram } from '@DesignSystemComponents/Pictogram';

interface UspProps extends UspType {
  size?: 'Default' | 'Small';
}

export interface UspsProps extends ClassNameProps {
  items: UspType[];
  title?: string;
}

const UspStyles: SxStyleProp = {
  display: 'block',
  textDecoration: 'none',
  color: 'iconDimmedheavy',
  position: 'relative',
  '&:hover, :visited': {
    color: 'iconDimmedheavy',
  },
};

export const UspContent: React.FC<Omit<UspProps, 'link' | 'id'> & ClassNameProps> = ({
  description,
  title,
  illustration,
  iconBackgroundColor,
  className,
  size = 'Default',
}) => {
  const iconColor = 'backgroundLightsubtle';

  return (
    <div
      className={className}
      sx={{
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
      }}
    >
      <Pictogram
        size={size === 'Default' ? '64' : '36'}
        name={illustration as unknown as Illustrations}
        backgroundColor={iconBackgroundColor ? iconColor : undefined}
        sx={{
          color: 'iconDefault',
          marginRight: '2xs',
          marginBottom: 0,
        }}
      />
      <div>
        <Heading
          variant="smallbold"
          as="h3"
          sx={{ marginBottom: '5xs' }}
        >
          {title}
        </Heading>
        {description && (
          <Paragraph
            variant="extrasmall"
            sx={{ color: 'iconDimmedheavy' }}
          >
            {description}
          </Paragraph>
        )}
      </div>
    </div>
  );
};

export const Usp: React.FC<Omit<UspProps, 'id'> & ClassNameProps> = ({
  className,
  link,
  ...usp
}) =>
  link ? (
    <WithClickTracking action="usp">
      <Link
        sx={UspStyles}
        className={className}
        href={link.href}
        target={link.target}
        rel={link.rel?.join(' ')}
      >
        <UspContent {...usp} />
      </Link>
    </WithClickTracking>
  ) : (
    <div
      className={className}
      sx={UspStyles}
    >
      <UspContent {...usp} />
    </div>
  );

const ThreeByThreeConfig = (): SxStyleProp => ({
  gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
});

const TwoByTwoConfig = (): SxStyleProp => ({
  gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
});

export const Usps: React.FC<UspsProps> = ({ className, items, title }) => {
  if (!items?.length) {
    return null;
  }

  const isOddOrByThree = items.length % 3 === 0 || items.length % 2 === 1;

  return (
    <HydrateWhenInViewport>
      <section
        data-id="usps"
        className={className}
      >
        {!!title && (
          <Heading
            as="h2"
            variant="largebold"
            sx={{
              marginBottom: '2xl',
            }}
          >
            {title}
          </Heading>
        )}

        <div
          sx={{
            display: 'grid',
            rowGap: '2xl',
            columnGap: 's',
            ...(isOddOrByThree ? ThreeByThreeConfig() : TwoByTwoConfig()),
          }}
        >
          {items.map((usp, i) => (
            <Usp
              {...usp}
              key={i}
            />
          ))}
        </div>
      </section>
    </HydrateWhenInViewport>
  );
};
